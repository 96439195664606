import React from "react";
import Container from "../components/global/container";
import Layout from "../components/global/layout";

class NotFoundPage extends React.Component {
    render() {
        return (
            <Layout location={this.props.location} title={`Page Not Found`}>
                <section className="full-screen background-black">
                    <Container className="h-100">
                        <div className="row h-100 align-items-center justify-content-center position-relative z-index-3">
                            <div className="col-12 text-center">
                                <div
                                    className={`mb-10 text-white font-weight-thin font-size-md`}
                                >
                                    OOPS... WE COULDN'T FIND YOUR PAGE
                                </div>
                                <h1
                                    className={`text-mask-primary font-weight-bold line-height-2 font-size-xl`}
                                >
                                    ERROR 404 Not Found
                                </h1>
                            </div>
                        </div>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default NotFoundPage;
